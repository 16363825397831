import Cookies from "js-cookie";
import { Constants } from "./Constants";
import { ConsoleLog } from "./Common"
import ApiCall from "./ApiCall";
import { tInit } from "./Translation";

export function AppDefaults() {
  let Language = Cookies.get("Language");
  if (!Language) {
    Language = "IT";
  }
  return {
    IsOnline: null,
    App: {
      Version: {
        IdVersion: Constants.Version.IdVersion,
        Version: Constants.Version.Version,
        Date: null,
      },
      Languages: []
    },
    User: null,
    Language: Language,
    Datetime: null
  };
}

export function AppGet(Callback) {
  let App = AppDefaults();
  tInit(App.Language, () => {
    ConsoleLog("[APP]", "Loading App from API...");

    ApiCall("POST", "app", null, async (Data) => {
      if (Data.Result && Data.Data) {
        ConsoleLog("[APP]", "Loaded App.");

        if (Constants.Version.IdVersion < Data.Data.App.Version.IdVersion) {
          AppEmptyCache();
          window.location.href = "/";
        }

        App.IsOnline = true;
        App.App = Data.Data.App;
        App.User = Data.Data.User;
        App.Datetime = Data.Datetime;

        if (Callback) {
          Callback(App);
        }
      } else {
        ConsoleLog("[APP]", "Error connecting to API!");
        App.IsOnline = false;
        if (Callback) {
          Callback(App);
        }
      }
    });
  });
}

export function AppEmptyCache() {
  if ("caches" in window) {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  }
}