import React, { Suspense } from "react";
import { HashRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { Routes } from "Functions/Constants";

import Preloader from "Components/Preloader";

export function AppRoutes(props) {
  let App = props.App;
  let AppRoutes = Routes(App);

  return (
    App ? (
      <Router>
        <Suspense fallback={<Preloader />}>
          <Switch>
            {
              Object.keys(AppRoutes).map((path) => {
                let item = AppRoutes[path];
                let ItemRoutes = [];
                ItemRoutes.push(<Route key={path} title={item.title} exact={item.exact || (item.params && item.params.length > 0)} path={path} render={() => item.render} />);
                if (item.params && item.params.length > 0) {
                  item.params.forEach(function (thisItem) {
                    ItemRoutes.push(<Route key={path + thisItem} title={item.title} exact={true} path={path + thisItem} render={() => item.render} />);
                  });
                }
                return ItemRoutes;
              })
            }
            <Route path="*" render={() => <Redirect to="/" />} />
          </Switch>
        </Suspense>
      </Router>
    ) : (
      <Preloader />
    )
  );
}

export default AppRoutes;