import React, { lazy } from "react";
import { Redirect } from "react-router";
import { t } from "Functions/Translation";
import LogoWhiteMenu from "Assets/Img/logo-white-menu.png";

const PageHomepage = lazy(() => import("Pages/Homepage"));
const PageProducts = lazy(() => import("Pages/Products"));
const PageProfile = lazy(() => import("Pages/Profile"));
const PageAdmin = lazy(() => import("Pages/Admin"));
const PageAdminFamilies = lazy(() => import("Pages/AdminFamilies"));
const PageAdminProducts = lazy(() => import("Pages/AdminProducts"));
const PageContacts = lazy(() => import("Pages/Contacts"));

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === "[::1]" ||
  // 127.0.0.0/8 are considered localhost for IPv4.
  window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

export const Constants = {
  ServiceWorker: false && !isLocalhost,
  GAaccount: "G-S5Q3XQ16TF",
  Endpoint: "https://www.padanalampadari.it/api/v1/",
  UpdateInterval: 20,
  Version: {
    IdVersion: 1,
    Version: "1.0.0",
  }
}

export function Routes(App) {

  return {
    "/": {
      title: <img src={LogoWhiteMenu} alt="Homepage" className="shadow" />,
      icon: null,
      exact: true,
      menu: {
        visible: false,
        title: true
      },
      render: App ? <PageHomepage App={App} /> : null
    },
    "/products": {
      title: t("pages.products.title", false),
      icon: ["far", "lightbulb"],
      exact: false,
      params: [
        "/:IdCategory"
      ],
      menu: {
        visible: true,
        title: true
      },
      render: App ? <PageProducts App={App} /> : null
    },
    "/profile": {
      title: t("pages.profile.title", false),
      icon: ["far", "user-circle"],
      exact: true,
      menu: {
        visible: false,
        title: true
      },
      render: App && App.User ? <PageProfile App={App} /> : <Redirect to="/" />
    },
    "/admin": {
      title: t("pages.admin.title", false),
      icon: ["fas", "user-cog"],
      exact: true,
      menu: {
        visible: false,
        title: true
      },
      render: App && App.User ? <PageAdmin App={App} /> : <Redirect to="/" />
    },
    "/admin/families": {
      title: t("pages.admin.title", false),
      icon: ["fas", "user-cog"],
      exact: true,
      menu: {
        visible: false,
        title: true
      },
      render: App && App.User ? <PageAdminFamilies App={App} /> : <Redirect to="/" />
    },
    "/admin/products": {
      title: t("pages.admin.title", false),
      icon: ["fas", "user-cog"],
      exact: true,
      menu: {
        visible: false,
        title: true
      },
      render: App && App.User ? <PageAdminProducts App={App} /> : <Redirect to="/" />
    },
    "/contacts": {
      title: t("pages.contacts.title", false),
      icon: ["fas", "phone"],
      exact: true,
      menu: {
        visible: true,
        title: true
      },
      render: App ? <PageContacts App={App} /> : null
    },
  };
}
