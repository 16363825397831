import Cookies from "js-cookie";
import ApiCall from "./ApiCall";
import Markdown from "Components/Markdown";

export function tInit(IdLanguage, Callback) {
  if (window.locale && window.locale.Language === IdLanguage) {
    if (Callback) {
      Callback(window.locale);
    }
  }
  ApiCall("POST", "app/locales", {
    IdLanguage: IdLanguage
  }, async (Data) => {
    if (Data.Result && Data.Data) {
      window.locale = {
        Language: IdLanguage,
        Fields: Data.Data
      };
      if (Callback) {
        Callback(window.locale);
      }
    }
  });
}

export function t(field, markdown) {
  if (window.locale) {
    let value = "";
    if (window.locale.Fields[field]) {
      value = window.locale.Fields[field];
    } else {
      value = (<b className="text-danger">{field}</b>);
      markdown = false;
      console.error("[LOCALE]", field);
    }
    if (markdown === false) {
      return value;
    }
    return (<Markdown>{value}</Markdown>);
  }
  return "";
}

export function setLanguage(Language) {
  tInit(Language, () => {
    Cookies.set("Language", Language);
    window.location.reload(true);
  });
}
