import React, { useEffect, useState } from "react";
import { ConsoleLog } from "Functions/Common"
import { AppGet } from "Functions/AppHelper"
import AppRoutes from "Components/Routes"

import "bootstrap/dist/css/bootstrap.min.css";
import "Assets/Css/Fonts.css";
import "Assets/Css/Common.css";
import "Assets/Css/Overrides.css";
import "Assets/Css/Card.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import usePageTracking from "Components/Routes/tracking";

library.add(far, fas, fab);

function App() {
  usePageTracking();
  const [App, setApp] = useState(null);
  const [AppInitialized, setAppInitialized] = useState(false);
  let AppDataTimeout = null;

  useEffect(() => {
    AppInitialize();
    return () => { clearTimeout(AppDataTimeout); };
    // eslint-disable-next-line
  }, []);

  function AppInitialize() {
    if (!AppInitialized) {
      setAppInitialized(true);
      ConsoleLog("[APP]", "Initializing App...");
      AppUpdate();
    }
  }

  function AppUpdate(Forced, Callback) {
    ConsoleLog("[APP]", (Forced ? "[FORCED] " : "") + "Updating App...");
    AppGet((NewApp) => {
      NewApp.AppUpdate = (Forced, Callback) => {
        AppUpdate(Forced, Callback);
      };
      setApp(NewApp);
      if (Callback) {
        Callback();
      }
    });
  }

  return <AppRoutes App={App} />;
}

export default App;
