import React from "react";
import ReactMarkdown from "react-markdown";

function Markdown(props) {
  let lines = props.children.split("\n");

  return (
    <>
      {
        lines.map((value, idx) => {
          return (
            <span key={idx}>
              <ReactMarkdown skipHtml unwrapDisallowed allowedElements={["br", "p", "strong", "i", "em", "h1", "h2", "h3", "h4", "h5", "h6"]} components={{
                em: ({ node, ...props }) => <u {...props} />,
                p: React.Fragment,
              }}>
                {value}
              </ReactMarkdown>
              {
                lines.length > 1 ? (
                  <br />
                ) : null
              }
            </span>
          );
        })
      }
    </>
  );
}

export default Markdown;
