import { Constants } from "./Constants";
import sha256 from "crypto-js/sha256";

async function ApiCall(method, url, data, callback, cachedCallback) {
  if (navigator.onLine) {
    let requestOptions = null;
    if (method === "GET") {
      requestOptions = {
        method: "GET",
        credentials: "include"
      };
      if (data) {
        var esc = encodeURIComponent;
        url += "?" + Object.keys(data)
          .map((k) => esc(k) + "=" + esc(data[k]))
          .join("&");
      }
    } else {
      requestOptions = {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(data)
      };
    }
    let sessionKey = sha256(JSON.stringify({
      url: url,
      requestOptions: requestOptions
    }));

    if (cachedCallback) {
      let chachedR = window.sessionStorage.getItem(sessionKey);
      if (chachedR) {
        if (cachedCallback === true) {
          callback(JSON.parse(chachedR), true);
        } else {
          cachedCallback(JSON.parse(chachedR));
        }
      }
    }

    fetch(Constants.Endpoint + url, requestOptions)
      .then((response) => response.json())
      .then(function (r) {
        if (cachedCallback) {
          window.sessionStorage.setItem(sessionKey, JSON.stringify(r));
        }
        callback(r);
      }).catch(function (r, e) {
        // TODO
        callback(r);
      });
  } else {
    callback({
      Result: false,
      Data: null,
      Error: "no_connection",
    });
  }
}

export default ApiCall;
