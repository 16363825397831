import React from 'react';
import './style.css';
import LogoWhite from 'Assets/Img/logo-white.png';
import Spinner from 'react-bootstrap/Spinner';

function Preloader(props) {
  return (
    <div className="preloader">
      <div>
        <img src={LogoWhite} alt="Padana Lampadari" className="image-fluid d-block mx-auto my-4 shadow" />
        <Spinner animation="border" />
      </div>
    </div>
  );
}

export default Preloader;